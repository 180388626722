<template>
  <section>
    <title-section
      title="Usuarios"
    >
      <div class="level-item">
        <button
          class="button button_micro_dark"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="csvDownload()"
        >
          EXPORTAR
        </button>
      </div>
    </title-section>
    <users-main
      ref="usersMainRef"
    />
  </section>
</template>

<script>
export default {
  name: 'Users',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    UsersMain: () => import('@/components/Users/UsersMain.vue')
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async csvDownload () {
      this.loading = true
      await this.$refs.usersMainRef.csvDownloadCustomersAction()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 200px;
  }
</style>
